
.container-fluid,html,body{
  margin:0;
  padding:0;
  height: 100%;
}
html { font-size: 1em; }

body {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen",
    "Ubuntu", "Cantarell", "Fira Sans", "Droid Sans", "Helvetica Neue",
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  font-family: 'Roboto', sans-serif;
}
label{width:100%}
code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, "Courier New",
    monospace;
}
.padding-pdf-50{
  padding: 50px 0;
}
.padding-pdf-15{
  padding: 15px 0;
}

.button-lezioni-arrow{
  text-align: center;
  margin-top:15px;
  margin-bottom:15px;
}

.span-bottone{
  color:white;
  border:0px solid white;
  border-radius:3px;
  display:inline-block;
  cursor:pointer;
  font-family:Arial;
  font-size:17px;
  padding:6px 5px;
  text-decoration:none;
  opacity: 0.9;
  -webkit-border-radius: 10px;
-moz-border-radius: 10px;
border-radius: 10px;
}
.allcorso{
  font-family: 'Quicksand', sans-serif;
  height: 100%;
  
}
.h-100{
  height: 100%;
}
.blog img {
  max-width: 100%;
}
.saleitem  img {
  max-width: 100%;
}
.corso-menu-titolo, .corso-menu-titolo-mobile,.corso-titolo{
  font-family: 'Quicksand', sans-serif;
  text-align:center;
  font-size:45px;
  cursor:pointer;
}
.corso-menu-titolo{
  font-size:35px;
}

.corso-menu-titolo-mobile{
  text-align:center;
  font-size:30px;
  cursor:pointer;
}
.corso-menu-titolo:hover{
  transition: all 1s;
  background-color: rgb(239, 238, 238);
}
.padding-header{
  padding-top:30px
}
.pulse{
  transform: scale(0.9);
	animation: pulse-black 2s infinite;
}
.linkpdf{
  text-decoration: none;
}

@keyframes pulse-black {
	0% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0.7);
	}
	
	70% {
		transform: scale(1);
		box-shadow: 0 0 0 10px rgba(0, 0, 0, 0);
	}
	
	100% {
		transform: scale(0.99);
		box-shadow: 0 0 0 0 rgba(0, 0, 0, 0);
	}
}


.lezione-item{
  padding: 2px;
  background-color: #d9d9d9;
  color:#695754;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
  width:80%;
  font-weight: lighter;
  margin-bottom:10px;
  margin:0 auto;
  text-align: left;
  margin-bottom: 5px;
}
.lezione-titolo{
  color:#695754;
}
.corso-menu-sottotiolo{
  text-align: center;
  padding-bottom: 20px;
  width:80%;
  font-size: 16px;
  font-family: 'Roboto';
  margin:0 auto;
}
.button-newsletter{
    border:0px solid white;
    border-radius:40px;
    display:inline-block;
    cursor:pointer;
    font-family:Arial;
    font-size:17px;
    padding:16px 31px;
    text-decoration:none;
}
.button-newsletter-pay{
  border:0px solid white;
  border-radius:3px;
  display:inline-block;
  cursor:pointer;
  font-family:Arial;
  font-size:17px;
  padding:16px 31px;
  text-decoration:none;
  background-color: #00910C;
  color:white;
  font-weight: bold;
}
.button-newsletter:hover {
  transition: 0.3s ease;
  opacity: 1;
}
.button-newsletter-opacity{
  opacity: 0.5 !important
}

.borderRed{
  border:1px solid red
}
.corso-titolo{
  margin-bottom: 15px;
}
.pictruceinpictruce{
  position: fixed;
  right: 10px;
  bottom: 10px;
  width: 100%;
  height: 100%;
  max-height: 200px;
  max-width: 300px;
}
.video-nomobile{
  width: 100%;
  height: 100%;
  margin: 0 auto;
  height: 348px;
  max-width: 650px;
}
.video-mobile{
  width: 100%;
  height: 100%;
  max-width:580px;
  height:250px;
}
.corso-descrizione>p{
  text-align: left;
}
.header {
  position: relative;
  display: -ms-flexbox;
  display: flex;
  -ms-flex-wrap: wrap;
  flex-wrap: wrap;
  -ms-flex-direction: column;
  flex-direction: column;
  -ms-flex-pack: center;
  justify-content: center;
  min-height: 400px;
  padding-top: 4rem;
  padding-bottom: 4rem;
  color: #fff;
  background-color: #777;
  text-shadow: 2px 0px grey;
}

.login-container{
  margin-top: 5%;
  margin-bottom: 5%;
}
.login-form-2{
  padding: 2%;
  background: #0062cc;
  box-shadow: 0 5px 8px 0 rgba(0, 0, 0, 0.2), 0 9px 26px 0 rgba(0, 0, 0, 0.19);
}
.login-form-2 h3{
  text-align: center;
  color: #fff;
}
.login-container form{
  padding: 10%;
}
.btnSubmit
{
  width: 50%;
  border-radius: 1rem;
  padding: 1.5%;
  border: none;
  cursor: pointer;
}
.login-form-2 .btnSubmit{
  font-weight: 600;
  color: #0062cc;
  background-color: #fff;
}
.login-form-2 .ForgetPwd{
  color: #fff;
  font-weight: 600;
  text-decoration: none;
  cursor: pointer;
}

.countdown-baw{
  position:fixed;
  bottom:70px;
  left:0;
  right:0;
  margin:0 auto;
  text-align:center
}
.countdown-baw-inner{
  -webkit-border-radius: 20px;
  -moz-border-radius: 20px;
  border-radius: 20px;
  width:90%;
  margin: 0 auto;
  font-family:'Roboto';
  color:white !important;
  background-color:#0062cc;
  opacity:0.8
}
.tabattiva{
  color:white !important;
  background-color:#007bffc0 !important;
}
.nav-link{
  cursor: pointer;
}
.boxrinnovaabbonamento{
  border: 1px solid #9192c4;
  padding: 10px;
  -webkit-border-radius: 5px;
  -moz-border-radius: 5px;
  border-radius: 5px;
}
.width100important > form{
  width: 100% !important;
}
.nopadding-nomargin > form{
  padding: 0 !important;
  margin: 0 !important;
}

.button-checkbox{
  height: 15px;
  width: 15px;
  background-color: white;
  -webkit-border-radius: 2px;
  -moz-border-radius: 2px;
  border-radius: 2px;
  margin-bottom:30px;
  
}
.regalo-label{
  padding:20px;
  padding-top:10px;
}
.title-cons,.title-cons-mobile{
  text-align: center;
}

.title-cons-mobile{
  font-size: 35px;
}
.title-cons{
  font-size: 50px;
}
.prezzononbarrato{
  font-weight: bold;
  color: green;
  font-size: 45px;
}
.prezzobarrato{
  color:rgb(236, 52, 52);
  text-decoration: line-through;
}
.monserrat-font{
  font-family: 'Montserrat', sans-serif;
  color:#053861;
}
.block-space{
  padding:20px;
}
.settimana-title{
  max-width: 50%;
  padding-left: 25px;
}
.arrow-block{
  padding: 10px 0px;
}
.arrow-blu{
  width:100%;
  max-width:30px;  
}
.text-arrow-blu-div{
  padding-left: 0;
  font-weight: 500;
  padding-bottom: 15px;
}
.bonus{
  padding-top: 30px;
  padding-bottom: 50px;
}
.title-bonus{
  font-weight: 600;
}