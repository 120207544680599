
 .inputNewsletter{
    padding:0;
    position: absolute;
    z-index: 1000;
 }
 /* .contentNewsletter{
  position: relative;
  top: 50%;
  transform: translateY(-50%);
  overflow-y:auto;
} */
 .inputNewsletter-background{
  position:fixed;
  top:0;
  left:0;
  bottom:0;
  right:0;
  background-color:white;
  height:100%;
  z-index: 999;
  overflow-y: scroll;
  padding-bottom: 50px;
}

 .butn {
   color: 000;
   background-color: white;
   font-size: 20px;
   padding: 10px;
   border: solid #000000 3px;
   text-decoration: none;
 }
 
 .butn:hover {
   text-decoration: none;
 }
 .socialicons{
   background-color: white;
   padding:0;
   margin:0;
 }