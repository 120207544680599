.itemmenu {
  color:black;
}
.itemmenu:hover{
  color: #2196F3;
  text-decoration: none
}
.menuactive{
  color: #2196F3;
  text-decoration: none
}
.tagcolor{
  background-color: #2196F3;
  color:white;
  font-size: 15px;
}
.button-social{
  font-size: 13px;
}
.headermenu{
  height: 60px;
  -webkit-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.33);
-moz-box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.33);
box-shadow: 0px 2px 3px 0px rgba(0,0,0,0.33);
}
@media screen and (min-width: 990px) {
  .bm-burger-button { display:none; }  /* show it on small screens */
}
.contenitore{
  min-height: 100vh;
  padding-top: 70px;
  padding-bottom: 50px;
}
.contenitore-corso{
  min-height: 100%;
}
.contenitore-nopaddingtop{
  margin-top: -70px;
}
/* https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/ */
.footer {   
  flex-shrink: 0; 
}
.App {
  text-align: center;
}
body{background-color: white}

.blog-box{
  position: relative;
  border:1px solid rgb(228, 228, 228);
  padding:10px;
  height: 100%;
}

.titleblog{
  font-family: 'Bebas Neue', cursive;
  color:rgb(27, 27, 27);
  text-decoration: none;
  font-size: 20px;
  margin-top:"18px"
}
.titleblogwhite{
  font-family: 'Bebas Neue', cursive;
  text-decoration: none;
  font-size: 20px;
  margin-top:"18px"
}
.descriptionblog{
  font-family: 'Quicksand', sans-serif;
}
.titleinfopage{
  font-family: 'Bebas Neue', cursive;
}

.contentinfopage{
  font-family: 'Quicksand', sans-serif;
}
.right-corso{
  height: 100%;
  padding-top: 15px;

}
.left-corso{
  min-height: calc(100vh - 100px);
  padding: 0;
  background-color: rgb(245, 245, 245);
}
.height-forced{
 min-height: 100%;
}
.floating-button{
  z-index: 999;
  position: fixed;
  bottom:45px;
  right: 15px;
}
.floating-button>a,.floating-button>li{
  background-color: #00468B  !important;
  color:white !important;
}
.gradientblue{
  background: rgb(72,137,194);
  background: linear-gradient(90deg, rgba(72,137,194,1) 0%, rgba(24,69,109,1) 100%, rgba(0,212,255,1) 100%);
}
img{
  max-width: 100%;
  max-height: 100%;
}
iframe{
  max-width: 100%;
  max-height: 100%;
}
.center_div{
  margin: 0 auto !important;
}

/**
* ----------------------------------------------
* Demo styles
* ----------------------------------------------
**/
.accordion {
  border: 1px solid rgba(0, 0, 0, 0.1);
  border-radius: 2px;
}

.accordion__item + .accordion__item {
  border-top: 1px solid rgba(0, 0, 0, 0.1);
}

.accordion__button {
  background-color: #053861;
  color: white;
  cursor: pointer;
  padding: 18px;
  width: 100%;
  text-align: left;
  border: none;
}

.accordion__button:hover {
  background-color: #082d4b;
}

.accordion__button:before {
  display: inline-block;
  content: '';
  height: 10px;
  width: 10px;
  margin-right: 12px;
  border-bottom: 2px solid currentColor;
  border-right: 2px solid currentColor;
  transform: rotate(-45deg);
}

.accordion__button[aria-expanded='true']::before,
.accordion__button[aria-selected='true']::before {
  transform: rotate(45deg);
}

[hidden] {
  display: none;
}

.accordion__panel {
  padding: 20px;
  animation: fadein 0.35s ease-in;
}

/* -------------------------------------------------- */
/* ---------------- Animation part ------------------ */
/* -------------------------------------------------- */

@keyframes fadein {
  0% {
      opacity: 0;
  }

  100% {
      opacity: 1;
  }
}

.recenzione-img  {
  float: left;    
  margin: 0 15px 0 0;
}
.recenzione-div{
  -webkit-border-radius: 10px;
  -moz-border-radius: 10px;
  border-radius: 10px;
  margin:0 auto;
  font-size: 13px;
  background-color: #E6F1F8;
  padding:15px;
}

.paymentinfodiv{
  border: 1px solid #E6F1F8;
  -webkit-border-bottom-right-radius: 10px;
  -webkit-border-bottom-left-radius: 10px;
  -moz-border-radius-bottomright: 10px;
  -moz-border-radius-bottomleft: 10px;
  border-bottom-right-radius: 10px;
  border-bottom-left-radius: 10px;
}

.paymentinfotitle{
  border: 1px solid #E6F1F8;
  -webkit-border-top-left-radius: 10px;
  -webkit-border-top-right-radius: 10px;
  -moz-border-radius-topleft: 10px;
  -moz-border-radius-topright: 10px;
  border-top-left-radius: 10px;
  border-top-right-radius: 10px;
}