html,body{
  margin:0;
  padding:0;
  height: 100%; 
} 
.contenitore{
  display: flex;
  height: 100%;
  flex-direction: column;
}
/* https://philipwalton.github.io/solved-by-flexbox/demos/sticky-footer/ */
.content { 
  flex: 1 0 auto; 
} 
.footer {   
  flex-shrink: 0; 
}
.App {
  text-align: center;
}
body{background-color: white}
.App-logo {
  animation: App-logo-spin infinite 20s linear;
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  background-color: #282c34;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: white;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}
.titleblog{
  font-family: 'Anton', sans-serif;
  color:black;
  text-decoration: none;
  font-size: 20px;
  margin-top:"18px"
}
.immagineblog{
  opacity:1;
}
.immagineblog:hover{
  transition:0.5s ease;
  opacity:0.8;
}
.titleblogwhite{
  font-family: 'Anton', sans-serif;
  text-decoration: none;
  font-size: 20px;
  margin-top:"18px"
}
.descriptionblog{
  font-family: 'Roboto', sans-serif;
}